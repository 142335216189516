@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
    height: 537px;
    width: 100%;
    overflow: hidden;
}

.shortCard {
    height: 465px;
}

.image {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.root,
.shortCard {
    @include mq.mq($until: medium) {
        height: 410px;
    }

    @include mq.mq($until: small) {
        height: 193px;
    }
}

.textWrapper {
    padding-top: var(--spacing-core-2);

    @include mq.mq($from: large) {
        color: #FFF;
        padding: var(--spacing-core-4);

        &::before {
            content: '';
            height: 100%;
            background: linear-gradient(
                180deg,
                rgb(0 0 0 / 0.01%) -6%,
                rgb(0 0 0 / 65%) 100%
            );
            transform: translateY(100%);
            opacity: 0;
            transition: transform 250ms ease-out, opacity 250ms ease-out;
        }
    }
}

.textWrapper,
.textWrapper::before {
    @include mq.mq($from: large) {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.text {
    position: relative;
    display: inline-block;
    z-index: 1;
    transition: opacity 250ms ease-out;

    @include mq.mq($from: large) {
        opacity: 0;
    }
}

.root:hover {
    .textWrapper::before {
        transform: translateY(0);
        opacity: 1;
    }

    .text {
        opacity: 1;
    }
}
