.root {
    background-color: var(--color-background-primary-default);
    display: flex;
    max-width: max-content;
    border-radius: 3rem;
    gap: var(--spacing-core-2);
    padding: var(--spacing-core-2) var(--spacing-core-4) var(--spacing-core-2) var(--spacing-core-2);
    align-items: center;
}

.logo {
    width: var(--sizing-core-10);
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 3rem;
    object-fit: contain;
}

.name {
    display: inline-block;
    word-break: break-word;
    font-weight: bold;
}
