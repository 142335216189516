.title,
.subtitle {
    display: inline-block;
}

.title {
    margin-right: var(--spacing-core-4);
    margin-bottom: 0;
}

.subtitle {
    margin-bottom: var(--spacing-core-6);
}

.section {
    margin-top: var(--spacing-core-4);
    padding-bottom: var(--spacing-core-4);
    border-bottom: var(--border-width-core-regular) solid #000;

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        border-bottom: none;
    }
}

.nutrition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
