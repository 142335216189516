.item {
    padding: var(--spacing-core-2) 0;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-3);
}

.product {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-core-3);
    align-items: center;
}

.productInfo {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-3);
}
