.modal {
    width: 100%;
    max-width: 496px;
    padding: 0;
}

.modalHeader {
    padding: var(--spacing-core-6);
}

.modalContent  {
    padding: 0 var(--spacing-core-6);
}

.modalFooter {
    padding: var(--spacing-core-6);
    display: flex;
    flex-direction: column;
}

.button {
    text-decoration: none;

    button {
        width: 100%;
    }
}
