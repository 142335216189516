@use '~@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;
@use '../../../../../styles/vars' as *;

.anchors,
.overview {
    display: none;
}

.overviewText {
    color: var(--color-foreground-inverted-default);
}

.icon {
    width: 20px;
    height: 20px;
    transition: transform var(--motion-duration-core-short);

    &Collapsed {
        transform: rotate(180deg);
    }
}

@include mq.mq($until: small) {
    .overview {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 30px;
        margin-left: auto;
        padding-right: var(--spacing-core-4);
        background: none;
        border: none;
        color: var(--color-foreground-inverted-default);
        cursor: pointer;
        gap: var(--spacing-core-1);

        &:focus {
            outline: none;
        }
    }

    .anchors {
        display: block;
        position: absolute;
        top: $allerhande-navigation-height;
        right: 0;
        left: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #131313;
        border-top: 1px solid var(--color-foreground-inverted-default);
        overflow: hidden;
        transition: all 0.2s ease;
        z-index: 4;

        &:not(.anchorsCollapsed) {
            opacity: 1;
            height: auto;
        }
    }   
    
    .anchorsCollapsed {
        opacity: 0;
        display: none;
    }
}
