@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.modalContent {
    padding: 0 var(--spacing-core-3);
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: thin;

    @include mq.mq($from: medium) {
        padding: 0 var(--spacing-core-6);
    }
}

.title {
    padding-top: var(--spacing-core-2);
}

.description {
    color: #838383;
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.item {
    padding: var(--spacing-core-2) 0;
    border-bottom: 1px solid #e9e9e9;
}
