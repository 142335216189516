@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.modalContent  {
    padding: 0 var(--spacing-core-3);
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: thin;

    @include mq.mq($from: medium) {
        padding: 0 var(--spacing-core-6);
    }
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.reusable {
    padding-top: var(--spacing-core-6);
}

.modalFooter {
    padding: var(--spacing-core-6);
    display: flex;
    flex-direction: column;
    box-shadow: 0 -4px 4px rgb(0 0 0 / 4%),0 -1px 2px rgb(0 0 0 / 3%);
}
