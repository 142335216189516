@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    background: #f7f7f7;
    padding: var(--spacing-core-8) var(--spacing-core-3);

    @include mq.mq($from: large) {
        padding: var(--spacing-core-14);
    }
}

.title {
    margin-bottom: 0;
}
