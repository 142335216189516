.content {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-core-12) auto 0 auto;
    max-width: 344px;
    align-items: center;
    gap: var(--spacing-core-4);
}

.illustration {
    margin-bottom: var(--spacing-core-2);
}

.button {
    margin-top: var(--spacing-core-2);
    width: 100%;
}
