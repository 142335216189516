@use '~@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.anchorLiItem {
    display: inline-flex;
}

.anchor {
    margin-right: 5px;
    text-decoration: none;
    padding: 16px var(--spacing-core-4);
    opacity: 0.6;
    transition: opacity 250ms ease;

    &:focus,
    &:hover {
        text-decoration: none;

        &:not(.activeAnchor) {
            opacity: 0.8;
        }
    }
}

.anchorText {
    font-weight: bold;
    line-height: 1;
    color: var(--color-foreground-inverted-default);
}

.activeAnchor {
    background-color: transparent;
    opacity: 1;
}


@include mq.mq($from: extra-small, $until: small) {
    .anchorLiItem {
        width: 50%;
        border-bottom: 1px solid var(--color-foreground-inverted-default);
    }
}

@include mq.mq($from: medium) {
    .anchor {
        padding: 10px var(--spacing-core-1);
    }
}

@include mq.mq($from: extra-large) {
    .anchor {
        margin-right: 10px;
    }
}

@media screen and (width >= 1200px) {
    .anchor {
        padding: 10px var(--spacing-core-4);
    }
}
