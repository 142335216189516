@use '../../../../../styles/vars' as *;

.root {
    width: 100%;
    height: $allerhande-navigation-height;
    background-color: var(--color-background-inverted-default);
    z-index: 100;
}

.grid {
    --grid-gutter: 0;

    max-width: $lane-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.wrapper {
    display: flex;
    z-index: 3;
}

@media print {
    .root {
        display: none;
    }
}
