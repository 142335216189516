.root {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-core-4);
}

.quickEntry {
    display: inline-flex;
    width: 100%;
    max-width: 344px;
    cursor: pointer;
}

.variant {
    background-color: var(--color-background-primary-default);

    &:hover {
        background-color: var(--color-background-primary-hover);
    }
}

.modalContent {
    padding: 0;
}

.icon {
    overflow: hidden;
}

.imageWrapper {
    height: 100%;
    width: 100%;
}