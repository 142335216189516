@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    @include mq.mq($from: large) {
        max-width: 708px;
        margin: 0 auto;
    }
}

.title {
    text-align: center;
    padding-bottom: var(--spacing-core-8);
    border-bottom: var(--border-width-core-regular) solid #000;
}

.accordion {
    div,
    summary {
        padding-left: 0;
        padding-right: 0;
    }

    hr {
        border-color: #000;
        margin: 0;
    }

    details:not([open]) ~ hr {
        display: none;
    }
}

.question {
    background: none;
}

.questionTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    padding: var(--spacing-core-4) 0;
    outline: none;
    cursor: pointer;
}

.showMore {
    margin-top: var(--spacing-core-8);
    width: 100%;
    text-align: center;
    padding: 0;
    border: none;
    background: inherit;
}

.showMoreContent {
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

.showMoreIcon {
    margin-left: var(--spacing-core-2);
    vertical-align: middle;
}
