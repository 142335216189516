@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-core-4);
    justify-content: space-between;

    @include mq.mq($from: large) {
        margin-top: var(--spacing-core-8);
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-core-4);
}

.border {
    border-top: var(--border-width-core-regular) solid var(--color-background-secondary-hover);
    margin-top: var(--spacing-core-2);

    @include mq.mq($from: large) {
        margin-top: var(--spacing-core-4);
    }
}

.showMore {
    align-self: center;
    height: 48px;
    width: 60px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    background: transparent;
    border: none;

    &:hover,
    &:active,
    &:focus-visible {
        .dot {
            background: var(--color-foreground-primary-default);
        }
    }
}

.dot {
    position: relative;
    display: inline-block;
    padding: 2px;
    width: 3px;
    height: 3px;
    margin-right: var(--spacing-core-1);
    border: var(--border-width-core-thick) solid var(--color-foreground-primary-default);
    background: var(--color-background-primary-default);
    border-radius: 100%;
    transition: 0.1s all 0.1s ease-in-out;

    &:first-of-type {
        transition-delay: 0s;
    }

    &:last-of-type {
        margin-right: 0;
        transition-delay: 0.1s;
    }
}

dialog[open].modal {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;

    @include mq.mq($until: small) {
        height: 100%;
    }
    
    @include mq.mq($from: medium) {
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }

    @media print {
        display: none;
    }
}

.modalHeader {
    padding: var(--spacing-core-6);
}

.modalContent  {
    flex-grow: 1;
    padding: 0 var(--spacing-core-6) var(--spacing-core-6) var(--spacing-core-6);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-4);
}
