@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    background: var(--color-background-primary-default);
    border-radius: 48px 0 0;
    overflow: hidden;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-areas: 
        "image"
        "side";

    @include mq.mq($from: extra-large) {
        border-radius: 0 0 0 96px;
        grid-template-areas: 
        "image side side";
    }
}

.image {
    grid-area: image;
    height: 160px;

    @include mq.mq($from: medium) {
        height: 213px;
    }

    @include mq.mq($from: extra-large) {
        height: 100%;
    }
}

.side {
    grid-area: side;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-12);
    padding: var(--spacing-core-6) var(--spacing-core-4);

    @include mq.mq($from: medium) {
        gap: var(--spacing-core-6);
        padding: var(--spacing-core-6);
        flex-direction: row;
    }

    @include mq.mq($from: extra-large) {
        gap: var(--spacing-core-8);
        padding: var(--spacing-core-6) var(--spacing-core-8);
    }

    > * {
        flex: 1 0;
    }
}
