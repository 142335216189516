@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.titleWrapper {
    display: flex;
    align-items: flex-end;
    height: 5lh;
    padding-bottom: var(--spacing-core-2);
    overflow: hidden;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    vertical-align: text-bottom;
}

.links {
    padding-top: var(--spacing-core-8);

    @include mq.mq($from: large) {
        padding-top: var(--spacing-core-6);
    }
}

.link {
    margin-bottom: var(--spacing-core-3);

    &:nth-of-type(5n) {
        margin-bottom: var(--spacing-core-8);
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.linkAnchor {
    display: inline-block;
    color: #131313;
    text-decoration: none;
    /* stylelint-disable-next-line custom-property-pattern */
    font-family: var(--typography-body-regular-desktop-fontFamily);

    &:active,
    &:focus,
    &:hover,
    &:visited {
        color: #131313;
    }

    &:hover {
        .linkIcon {
            margin-right: var(--spacing-core-2);
        }
    }
}

.linkIcon {
    margin-right: var(--spacing-core-1);
    transition: margin-right 150ms linear;
}
