@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.grid {
    padding-bottom: var(--sizing-core-4);
}

@include mq.mq($from: extra-small, $until: large) {
    .grid {
        overflow-x: scroll;
        display: flex;
        flex-wrap: nowrap;
        scrollbar-width: none;
    }

    .gridItem {
        min-width: 350px;
    }

}
