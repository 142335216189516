@use '~@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.anchors {
    position: relative;
    flex: 0 0 auto;
    margin: 12px var(--spacing-core-2) 12px 0;
    padding: 0;
    border: none;
}

@include mq.mq($from: extra-small, $until: small) {
    .anchors {
        display: none;
    }
}

@include mq.mq($from: extra-large) {
    .anchors {
        margin: 12px  var(--spacing-core-2);
    }
}
