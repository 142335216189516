@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

dialog[open].modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border-radius: 0;

    @include mq.mq($from: medium) {
        margin: 50px auto;
        max-height: calc(100% - 100px);
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }

    @include mq.mq($from: large) {
        max-width: 600px;
    }
}

.modalHeader {
    border-bottom: 1px solid #e9e9e9;
    flex-shrink: 0;
}

.container {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
}

.panel {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-background-primary-default);
}
