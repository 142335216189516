@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.image {
    position: relative;
    grid-column: 1;
    overflow: hidden;
}

.imageRight {
    grid-column: 4;
}

.content {
    grid-column: span 4;
}

.contentWithImage {
    grid-column: span 3;
}
